var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"grey lighten-4"}},[_c('v-card-title',{staticClass:"text-uppercase font-weight-bold grey--text text--darken-3"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-text',{staticClass:"white"},[(_vm.rows.length === 0)?[_c('div',{staticClass:"pa-10 pt-5 mb-5 text-center"},[_c('v-icon',{attrs:{"size":"180","color":"grey lighten-2"}},[_vm._v(" mdi-tray-remove ")]),_c('div',{staticClass:"text-h5 grey--text text--lighten-2 text-uppercase font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("no_data_otb"))+" ")])],1)]:[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rows,"hide-default-footer":"","disable-pagination":"","item-class":_vm.getDatatableRowClass},scopedSlots:_vm._u([{key:"item.dimension",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"10","color":_vm.rowColor(item.dimension)}}),_c('b',[_vm._v(_vm._s(_vm.rowTitle(item.dimension)))])]}},{key:"item.room_revenue",fn:function(ref){
var item = ref.item;
return [_c('a-kpi-columns',{attrs:{"kpi":"room_revenue","value":item.room_revenue,"vs_values":[item.room_revenue_vs],"vs_periods":['stly'],"x_small":""}})]}},{key:"item.room_revenue_avg",fn:function(ref){
var item = ref.item;
return [_c('a-kpi-columns',{attrs:{"kpi":"room_revenue_avg","value":item.room_revenue_avg,"vs_values":[item.room_revenue_avg_vs],"vs_periods":['stly'],"x_small":""}})]}},{key:"item.rn",fn:function(ref){
var item = ref.item;
return [_c('a-kpi-columns',{attrs:{"kpi":"rn","value":item.rn,"vs_values":[item.rn_vs],"vs_periods":['stly'],"x_small":""}})]}},{key:"item.rn_avg",fn:function(ref){
var item = ref.item;
return [_c('a-kpi-columns',{attrs:{"kpi":"rn_avg","value":item.rn_avg,"vs_values":[item.rn_avg_vs],"vs_periods":['stly'],"x_small":""}})]}},{key:"item.adr",fn:function(ref){
var item = ref.item;
return [_c('a-kpi-columns',{attrs:{"kpi":"adr","value":item.adr,"vs_values":[item.adr_vs],"vs_periods":['stly'],"x_small":""}})]}},{key:"item.occupancy",fn:function(ref){
var item = ref.item;
return [_c('a-kpi-columns',{attrs:{"kpi":"occupancy","value":item.occupancy,"vs_values":[item.occupancy],"vs_periods":['stly'],"x_small":""}})]}}],null,true)})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }