<template>
  <div id="segmentation-report-app">
    <v-tabs
      background-color="transparent"
      slider-size="2"
      v-model="tab"
      @change="updateKpiGraph"
      dark
      grow
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-if="existTab('rn')">
        {{ $t("rn") }}
      </v-tab>
      <v-tab v-if="existTab('room_revenue')">
        {{ $t("room_revenue") }}
      </v-tab>
      <v-tab v-if="existTab('adr')">
        {{ $t("adr") }}
      </v-tab>
      <v-tab v-if="existTab('occupancy')">
        {{ $t("occupancy") }}
      </v-tab>
    </v-tabs>
    <a-base-component :subscribed_modules="subscribed_modules">
      <template v-slot:default>
        <v-tabs-items v-model="tab">
          <v-tab-item v-if="existTab('rn')">
            <a-card
              v-if="isGraphNotEmpty"
              id="rms-segmentation-report-chart"
              :no_title="true"
              class="mb-5"
            >
              <v-card-title
                class="text-uppercase font-weight-bold grey--text text--darken-3"
              >
                {{ $t("rn") }}
              </v-card-title>
              <a-hotelsdot-segmentation-report-rn-chart
                :series="segmentation_report_graph_filtered"
                :labels="segmentation_report_graph.labels"
                height="450"
                class="pt-5"
              ></a-hotelsdot-segmentation-report-rn-chart>
              <v-row>
                <v-col
                  cols="12"
                  class="pt-5 pb-10 d-flex justify-center align-center"
                >
                  <div
                    class="grey lighten-4 d-flex justify-center align-center flex-wrap rounded-xl pt-1 pb-0 px-2"
                  >
                    <v-chip
                      v-for="(serie,
                      index) in segmentation_report_graph_filtered.otb"
                      :key="index"
                      :color="getDimensionColor(index)"
                      class="ml-1 mb-1 text-uppercase white--text font-weight-bold"
                      x-small
                    >
                      {{ getDimensionName(index) }}
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
            </a-card>
            <a-card v-else :no_title="true" class="pa-10 pt-5 mb-5 text-center">
              <v-icon size="180" color="grey lighten-2">
                mdi-tray-remove
              </v-icon>
              <div
                class="text-h5 grey--text text--lighten-2 text-uppercase font-weight-bold"
              >
                {{ $t("no_data_otb") }}
              </div>
            </a-card>
          </v-tab-item>
          <v-tab-item v-if="existTab('room_revenue')">
            <a-card
              v-if="isGraphNotEmpty"
              id="rms-segmentation-report-chart"
              :no_title="true"
              class="mb-5"
            >
              <v-card-title
                class="text-uppercase font-weight-bold grey--text text--darken-3"
              >
                {{ $t("room_revenue") }}
              </v-card-title>
              <a-hotelsdot-segmentation-report-roomrevenue-chart
                :series="segmentation_report_graph_filtered"
                :labels="segmentation_report_graph.labels"
                height="450"
                class="pt-5"
              ></a-hotelsdot-segmentation-report-roomrevenue-chart>
              <v-row>
                <v-col
                  cols="12"
                  class="pt-5 pb-10 d-flex justify-center align-center"
                >
                  <div
                    class="grey lighten-4 d-flex justify-center align-center flex-wrap rounded-xl pt-1 pb-0 px-2"
                  >
                    <v-chip
                      v-for="(serie,
                      index) in segmentation_report_graph_filtered.otb"
                      :key="index"
                      :color="getDimensionColor(index)"
                      class="ml-1 mb-1 text-uppercase white--text font-weight-bold"
                      x-small
                    >
                      {{ getDimensionName(index) }}
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
            </a-card>
            <a-card v-else :no_title="true" class="pa-10 pt-5 mb-5 text-center">
              <v-icon size="180" color="grey lighten-2">
                mdi-tray-remove
              </v-icon>
              <div
                class="text-h5 grey--text text--lighten-2 text-uppercase font-weight-bold"
              >
                {{ $t("no_data_otb") }}
              </div>
            </a-card>
          </v-tab-item>
          <v-tab-item v-if="existTab('adr')">
            <a-card
              v-if="isGraphNotEmpty"
              id="rms-segmentation-report-chart"
              :no_title="true"
              class="mb-5"
            >
              <v-card-title
                class="text-uppercase font-weight-bold grey--text text--darken-3"
              >
                {{ $t("adr") }}
              </v-card-title>
              <a-hotelsdot-segmentation-report-adr-chart
                :series="segmentation_report_graph_filtered"
                :labels="segmentation_report_graph.labels"
                height="450"
                class="pt-5"
              ></a-hotelsdot-segmentation-report-adr-chart>
              <v-row>
                <v-col
                  cols="12"
                  class="pt-5 pb-10 d-flex justify-center align-center"
                >
                  <div
                    class="grey lighten-4 d-flex justify-center align-center flex-wrap rounded-xl pt-1 pb-0 px-2"
                  >
                    <v-chip
                      v-for="(serie,
                      index) in segmentation_report_graph_filtered.otb"
                      :key="index"
                      :color="getDimensionColor(index)"
                      class="ml-1 mb-1 text-uppercase white--text font-weight-bold"
                      x-small
                    >
                      {{ getDimensionName(index) }}
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
            </a-card>
            <a-card v-else :no_title="true" class="pa-10 pt-5 mb-5 text-center">
              <v-icon size="180" color="grey lighten-2">
                mdi-tray-remove
              </v-icon>
              <div
                class="text-h5 grey--text text--lighten-2 text-uppercase font-weight-bold"
              >
                {{ $t("no_data_otb") }}
              </div>
            </a-card>
          </v-tab-item>
          <v-tab-item v-if="existTab('occupancy')">
            <a-card
              id="rms-segmentation-report-chart"
              :no_title="true"
              class="mb-5"
            >
              <v-card-title
                class="text-uppercase font-weight-bold grey--text text--darken-3"
              >
                {{ $t("occupancy") }}
              </v-card-title>
              <a-hotelsdot-segmentation-report-occupancy-chart
                :series="segmentation_report_graph_filtered"
                :labels="segmentation_report_graph.labels"
                height="450"
                class="pt-5"
              ></a-hotelsdot-segmentation-report-occupancy-chart>
              <v-row>
                <v-col
                  cols="12"
                  class="pt-5 pb-10 d-flex justify-center align-center"
                >
                  <div
                    class="grey lighten-4 d-flex justify-center align-center flex-wrap rounded-xl pt-1 pb-0 px-2"
                  >
                    <v-chip
                      v-for="(serie,
                      index) in segmentation_report_graph_filtered.otb"
                      :key="index"
                      :color="getDimensionColor(index)"
                      class="ml-1 mb-1 text-uppercase white--text font-weight-bold"
                      x-small
                    >
                      {{ getDimensionName(index) }}
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
            </a-card>
          </v-tab-item>
        </v-tabs-items>
        <a-rms-segmentation-report-table
          id="rms-segmentation-report-table"
          :rows="rows"
          class="mb-5"
        ></a-rms-segmentation-report-table>
      </template>
      <template v-slot:loading>
        <a-card class="mb-5">
          <v-row class="pt-5">
            <v-col cols="3" class="px-8">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
            <v-col cols="3" class="px-8">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
            <v-col cols="3" class="px-8">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
            <v-col cols="3" class="px-8">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
          </v-row>
        </a-card>
        <a-card>
          <v-row class="pt-5">
            <v-col cols="12">
              <v-skeleton-loader type="table"></v-skeleton-loader>
            </v-col>
          </v-row>
        </a-card>
      </template>
    </a-base-component>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import * as Utils from "@/utils.js";
import aCard from "@/components/aCard.vue";
import aBaseComponent from "@/components/aBaseComponent.vue";
import aHotelsdotSegmentationReportRnChart from "@/components/chart/hotelsdot/aSegmentationReportRnChart.vue";
import aHotelsdotSegmentationReportRoomrevenueChart from "@/components/chart/hotelsdot/aSegmentationReportRoomrevenueChart.vue";
import aHotelsdotSegmentationReportAdrChart from "@/components/chart/hotelsdot/aSegmentationReportAdrChart.vue";
import aHotelsdotSegmentationReportOccupancyChart from "@/components/chart/hotelsdot/aSegmentationReportOccupancyChart.vue";
import aRmsSegmentationReportTable from "@/components/rms/hotelsdot/aRmsSegmentationReportTable.vue";

export default {
  name: "rms-segment-report-hotelsdot-app",
  components: {
    aCard,
    aBaseComponent,
    aHotelsdotSegmentationReportRnChart,
    aHotelsdotSegmentationReportRoomrevenueChart,
    aHotelsdotSegmentationReportAdrChart,
    aHotelsdotSegmentationReportOccupancyChart,
    aRmsSegmentationReportTable
  },
  props: {
    dimension: {
      type: String,
      default: "segment"
    }
  },
  data: () => ({
    tab: 0,
    subscribed_modules: [
      "rms_segmentation_report_graph",
      "rms_segmentation_report_table",
      "segmentschannels_segments",
      "segmentschannels_segment_channels",
      "roomtype_types_mapped"
    ]
  }),
  methods: {
    updateKpiGraph() {
      let kpis = {
        segment: ["rn", "room_revenue", "adr"],
        channel: ["rn", "room_revenue", "adr"],
        roomtype: ["rn", "occupancy"]
      };
      this.kpi_graph = kpis[this.segmentation_report_dimension][this.tab];
    },
    existTab(kpi) {
      if (
        this.segmentation_report_dimension === "segment" &&
        (kpi === "rn" || kpi === "room_revenue" || kpi === "adr")
      ) {
        return true;
      } else if (
        this.segmentation_report_dimension === "channel" &&
        (kpi === "rn" || kpi === "room_revenue" || kpi === "adr")
      ) {
        return true;
      } else if (
        this.segmentation_report_dimension === "roomtype" &&
        (kpi === "rn" || kpi === "occupancy")
      ) {
        return true;
      }
      return false;
    },
    getDimensionName(code) {
      if (this.segmentation_report_dimension === "segment") {
        return Utils.getDimensionName(this.segments, code);
      } else if (this.segmentation_report_dimension === "channel") {
        return Utils.getDimensionName(this.channels, code);
      } else if (this.segmentation_report_dimension === "roomtype") {
        return Utils.getDimensionName(this.roomtypes, code);
      }
    },
    getDimensionColor(code) {
      if (this.segmentation_report_dimension === "segment") {
        return Utils.getDimensionColor(this.segments, code);
      } else if (this.segmentation_report_dimension === "channel") {
        return Utils.getDimensionColor(this.channels, code);
      } else if (this.segmentation_report_dimension === "roomtype") {
        return Utils.getDimensionColor(this.roomtypes, code);
      }
    }
  },
  computed: {
    kpi_graph: {
      get() {
        return this.$store.state.rms.segmentation_report_kpi_graph;
      },
      set(value) {
        this.$store.dispatch("setRmsSegmentationReportKpiGraph", value);
      }
    },
    segments() {
      return this.hotelsdot.series_ui
        ? Object.values(this.hotelsdot.series_ui).filter(
            s => s.type === "segment"
          )
        : null;
    },
    isGraphNotEmpty() {
      return (
        this.segmentation_report_graph &&
        Object.keys(this.segmentation_report_graph.otb).length > 0
      );
    },
    rows() {
      if (
        this.segmentation_report_table.length > 0 &&
        this.segmentation_report_dimension === "channel" &&
        this.channels.length > 0
      ) {
        return this.segmentation_report_table.map(row => {
          let ch = this.channels.filter(
            channel => channel.code === row.dimension
          );
          if (ch.length > 0) {
            row.dimension_name = ch[0].name;
          }
          return row;
        });
      }
      return this.segmentation_report_table;
    },
    ...mapState({
      hotelsdot: state => state.hotelsdot,
      segmentation_report_graph: state => state.rms.segmentation_report_graph,
      segmentation_report_table: state => state.rms.segmentation_report_table,
      segmentation_report_dimension: state =>
        state.rms.segmentation_report_dimension,
      segments: state => state.segmentschannels.segments,
      channels: state => state.segmentschannels.segment_channels
    }),
    ...mapGetters({
      segmentation_report_graph_filtered: "rms_get_dimension_period_graph",
      roomtypes: "pmsRoomTypes"
    })
  },
  mounted() {
    this.kpi_graph = "rn";
    this.$store.dispatch("setRmsSegmentationReportDimension", this.dimension);
  }
};
</script>

<style lang="scss" scoped>
.v-tabs-items {
  border-radius: 4px;
}
</style>
