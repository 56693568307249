<template>
  <v-card color="grey lighten-4">
    <v-card-title
      class="text-uppercase font-weight-bold grey--text text--darken-3"
    >
      {{ title }}
    </v-card-title>
    <v-card-text class="white">
      <template v-if="rows.length === 0">
        <div class="pa-10 pt-5 mb-5 text-center">
          <v-icon size="180" color="grey lighten-2">
            mdi-tray-remove
          </v-icon>
          <div
            class="text-h5 grey--text text--lighten-2 text-uppercase font-weight-bold"
          >
            {{ $t("no_data_otb") }}
          </div>
        </div>
      </template>
      <template v-else>
        <v-data-table
          :headers="headers"
          :items="rows"
          hide-default-footer
          disable-pagination
          :item-class="getDatatableRowClass"
        >
          <template v-slot:[`item.dimension`]="{ item }">
            <v-avatar
              size="10"
              class="mr-2"
              :color="rowColor(item.dimension)"
            ></v-avatar>
            <b>{{ rowTitle(item.dimension) }}</b>
          </template>
          <template v-slot:[`item.room_revenue`]="{ item }">
            <a-kpi-columns
              kpi="room_revenue"
              :value="item.room_revenue"
              :vs_values="[item.room_revenue_vs]"
              :vs_periods="['stly']"
              x_small
            ></a-kpi-columns>
          </template>
          <template v-slot:[`item.room_revenue_avg`]="{ item }">
            <a-kpi-columns
              kpi="room_revenue_avg"
              :value="item.room_revenue_avg"
              :vs_values="[item.room_revenue_avg_vs]"
              :vs_periods="['stly']"
              x_small
            ></a-kpi-columns>
          </template>
          <template v-slot:[`item.rn`]="{ item }">
            <a-kpi-columns
              kpi="rn"
              :value="item.rn"
              :vs_values="[item.rn_vs]"
              :vs_periods="['stly']"
              x_small
            ></a-kpi-columns>
          </template>
          <template v-slot:[`item.rn_avg`]="{ item }">
            <a-kpi-columns
              kpi="rn_avg"
              :value="item.rn_avg"
              :vs_values="[item.rn_avg_vs]"
              :vs_periods="['stly']"
              x_small
            ></a-kpi-columns>
          </template>
          <template v-slot:[`item.adr`]="{ item }">
            <a-kpi-columns
              kpi="adr"
              :value="item.adr"
              :vs_values="[item.adr_vs]"
              :vs_periods="['stly']"
              x_small
            ></a-kpi-columns>
          </template>
          <template v-slot:[`item.occupancy`]="{ item }">
            <a-kpi-columns
              kpi="occupancy"
              :value="item.occupancy"
              :vs_values="[item.occupancy]"
              :vs_periods="['stly']"
              x_small
            ></a-kpi-columns>
          </template>
        </v-data-table>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import * as Utils from "@/utils.js";
import aKpiColumns from "@/components/rms_ui/aKpiColumns.vue";

export default {
  name: "a-rms-segmentation-report-table",
  components: {
    aKpiColumns
  },
  props: {
    rows: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    columns() {
      if (this.dimension === "roomtype") {
        return [
          {
            title: this.$t("rn"),
            value: "rn"
          },
          {
            title: this.$t("rn_avg"),
            value: "rn_avg"
          },
          {
            title: this.$t("occupancy"),
            value: "occupancy"
          }
        ];
      }
      return [
        {
          title: this.$t("room_revenue"),
          value: "room_revenue"
        },
        {
          title: this.$t("room_revenue_avg"),
          value: "room_revenue_avg"
        },
        {
          title: this.$t("rn"),
          value: "rn"
        },
        {
          title: this.$t("rn_avg"),
          value: "rn_avg"
        },
        {
          title: this.$t("adr"),
          value: "adr"
        }
      ];
    },
    headers() {
      const headers = this.columns.map(column => ({
        text: column.title,
        value: column.value,
        align: "center",
        sortable: true
      }));
      headers.unshift({
        text: this.$t(this.dimension),
        value: "dimension",
        align: "left",
        sortable: true
      });
      return headers;
    },
    title() {
      if (this.dimension === "roomtype") {
        return this.$t("overview_by_room_type");
      } else if (this.dimension === "channel") {
        return this.$t("overview_by_channel");
      }
      return this.$t("overview_by_segment");
    },
    ...mapState({
      dimension: state => state.rms.segmentation_report_dimension,
      segments: state => state.segmentschannels.segments,
      channels: state => state.segmentschannels.segment_channels
    }),
    ...mapGetters({
      roomtypes: "pmsRoomTypes"
    })
  },
  methods: {
    getDatatableRowClass() {
      return "a-datatable-row";
    },
    rowTitle(dimension) {
      if (this.dimension === "segment") {
        return Utils.getDimensionName(this.segments, dimension);
      } else if (this.dimension === "channel") {
        return Utils.getDimensionName(this.channels, dimension);
      } else if (this.dimension === "roomtype") {
        return Utils.getDimensionName(this.roomtypes, dimension);
      }
      return dimension;
    },
    rowColor(dimension) {
      if (this.dimension === "segment") {
        return Utils.getDimensionColor(this.segments, dimension);
      } else if (this.dimension === "channel") {
        return Utils.getDimensionColor(this.channels, dimension);
      } else if (this.dimension === "roomtype") {
        return Utils.getDimensionColor(this.roomtypes, dimension);
      }
      return "grey";
    }
  }
};
</script>
<style lang="scss" scoped></style>
