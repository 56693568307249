<template>
  <div class="d-flex">
    <zingchart
      :height="height"
      :width="width"
      :data="graph_data"
      :series="graph_series"
      :id="$options.name"
    ></zingchart>
  </div>
</template>
<script>
import Vue from "vue";
import * as Utils from "@/utils.js";
import { mapGetters, mapState } from "vuex";

export default {
  name: "a-hotelsdot-segmentation-report-adr-chart",
  components: {},
  props: {
    height: {
      type: String,
      Number,
      default: "250px"
    },
    width: {
      type: String,
      Number,
      default: "100%"
    },
    series: {
      type: Object,
      default: function() {
        return {};
      }
    },
    labels: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  methods: {
    isEmpty(values) {
      return values.reduce((partialSum, a) => partialSum + a, 0) === 0;
    }
  },
  computed: {
    graph_data() {
      return {
        type: "bar",
        crosshairX: this.chart_settings.crosshair_settings,
        globals: this.chart_settings.globals,
        gui: this.chart_settings.gui,
        backgroundColor: "none", // This is in the root
        plotarea: {
          margin: "20px 20px 50px 70px"
        },
        plot: {
          stacked: true,
          barWidth: "80%",
          borderRadius: 20
        },
        tooltip: {
          visible: false
        },
        scaleX: {
          lineWidth: 0,
          item: {
            paddingTop: "10px",
            color: "#333333"
          },
          tick: {
            size: 0
          },
          labels: this.labels.map(item => {
            if (item.length === 10) {
              return (
                Vue.moment(item).format("DD") +
                " " +
                Vue.moment(item)
                  .format("MMMM")
                  .substr(0, 1)
                  .toUpperCase() +
                Vue.moment(item)
                  .format("MMMM")
                  .substr(1, 2)
              );
            }
            return (
              Vue.moment(item)
                .format("MMMM")
                .substr(0, 1)
                .toUpperCase() +
              Vue.moment(item)
                .format("MMMM")
                .substr(1, 2)
            );
          })
        },
        scaleY: {
          lineWidth: 0,
          format: "%v€",
          guide: {
            lineStyle: "solid",
            lineColor: "#f5f5f5"
          },
          tick: {
            size: 0
          },
          item: {
            paddingRight: "10px",
            color: "#333333"
          }
        }
      };
    },
    graph_series() {
      const series = [];
      const series_ordered = Object.keys(this.series.otb).sort(
        (a, b) => this.series.otb[b][0] - this.series.otb[a][0]
      );
      for (const serie of series_ordered) {
        let color = "grey";
        if (this.dimension === "segment") {
          color = Utils.getDimensionColor(this.segments, serie);
        } else if (this.dimension === "channel") {
          color = Utils.getDimensionColor(this.channels, serie);
        } else if (this.dimension === "roomtype") {
          color = Utils.getDimensionColor(this.roomtypes, serie);
        }
        if (this.series.otb[serie] && !this.isEmpty(this.series.otb[serie])) {
          series.push({
            stack: 1,
            text: serie,
            values: this.series.otb[serie],
            backgroundColor: color,
            lineColor: color,
            marker: {
              backgroundColor: color
            }
          });
        }
      }
      for (const serie of series_ordered) {
        let color = "grey";
        if (this.dimension === "segment") {
          color = Utils.getDimensionColor(this.segments, serie);
        } else if (this.dimension === "channel") {
          color = Utils.getDimensionColor(this.channels, serie);
        } else if (this.dimension === "roomtype") {
          color = Utils.getDimensionColor(this.roomtypes, serie);
        }
        if (this.series.stly[serie] && !this.isEmpty(this.series.stly[serie])) {
          series.push({
            stack: 2,
            text: serie,
            alpha: 0.5,
            values: this.series.stly[serie],
            backgroundColor: color,
            lineColor: color,
            marker: {
              backgroundColor: color
            }
          });
        }
      }
      return series;
    },
    max_value() {
      let max = 0;
      let a = this.series.otb;
      for (let serie in a) {
        let serie_max = a[serie].reduce((a, b) => a + b, 0);
        if (serie_max > max) {
          max = serie_max;
        }
      }
      a = this.series.stly;
      for (let serie in a) {
        let serie_max = a[serie].reduce((a, b) => a + b, 0);
        if (serie_max > max) {
          max = serie_max;
        }
      }
      return parseInt(max + 5);
    },
    min_value() {
      let min = this.max_value;
      let a = this.series.otb;
      for (let serie in a) {
        let serie_min = a[serie].reduce((a, b) => a + b, 0);
        if (serie_min < min) {
          min = serie_min;
        }
      }
      a = this.series.stly;
      for (let serie in a) {
        let serie_min = a[serie].reduce((a, b) => a + b, 0);
        if (serie_min < min) {
          min = serie_min;
        }
      }

      return parseInt(min - 5 > 0 ? min - 5 : 0);
    },
    scaleValues() {
      return (
        this.min_value +
        ":" +
        this.max_value +
        ":" +
        parseInt((this.max_value - this.min_value) / 4)
      );
    },
    ...mapState({
      chart_settings: state => state.chart_settings,
      dimension: state => state.rms.segmentation_report_dimension,
      segments: state => state.segmentschannels.segments,
      channels: state => state.segmentschannels.segment_channels
    }),
    ...mapGetters({
      roomtypes: "pmsRoomTypes"
    })
  }
};
</script>
