import { render, staticRenderFns } from "./rmsSegmentationReportApp.vue?vue&type=template&id=4c1a7c33&scoped=true"
import script from "./rmsSegmentationReportApp.vue?vue&type=script&lang=js"
export * from "./rmsSegmentationReportApp.vue?vue&type=script&lang=js"
import style0 from "./rmsSegmentationReportApp.vue?vue&type=style&index=0&id=4c1a7c33&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c1a7c33",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCardTitle,VChip,VCol,VIcon,VRow,VSkeletonLoader,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider})
